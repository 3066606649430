import styled, {keyframes} from 'styled-components';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';
import general from '../../../utils/general';
import { useController } from './controller';

export const main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    width: 330px;
    height: 320px;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 240px;
    }

    @media (max-width: 900px) {
        width: 180px;
    }

    @media (max-width: 700px) {
        width: calc(100% - 20px);
        height: auto;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;
    }
`;
export const cardImage = styled.img<{src: string}>`
    border-radius: 15px;
    margin-bottom: 10px;
    max-height: 40%;
    max-width: 80%;
    object-fit: cover;
    object-position: center;
    background-color: ${colors.lightGrey};

    @media (max-width: 700px) {
        width: 38%;
        height: 100%;
        margin: 0;
        padding: 10px 0;
        background-color: transparent;
    }
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 20px;

    @media (max-width: 700px) {
        padding: 10px 0;
        justify-content: center;
        align-items: center;
    }
`;
export const cardTitles = styled.div`
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-family: ${fonts.main};

    @media (max-width: 700px) {
        display: flex;
        flex-direction: row;
        gap: 5px;
        text-align: left;
        width: auto;
        align-self: ceenter;
    }
`;
export const cardTitle = styled.h3`
    font-size: 18px;
    font-weight: 800;
    padding: 0 5px;
    flex: 1;

    @media (max-width: 1366px) {
        font-size: 16px;
    }
    @media (max-width: 700px) {
        font-size: 14px;
        text-align: center;
    }
`;
export const cardInfoArea = styled.div<{infoLength: number}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    height: 100%;

    @media(max-width: 700px) {
        height: auto;
    }
`;
export const cardInfoBit = styled.div`
    color: ${colors.darkerGrey};
    font-family: ${fonts.main};
    font-weight: 800;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;
export const infoIcon = styled(general.FontAwesomeIcon)`
    font-size: 2rem;

    @media (max-width: 1366px) {
        font-size: 1.5rem;
    }

    @media (max-width: 900px) {
        font-size: 1.75rem;
    }

    @media (max-width: 700px) {
        font-size: 1.5rem;
    }
`;
export const info = styled.p`
    font-size: 1.4rem;

    @media (max-width: 1366px) {
        font-size: 1rem;
    }

    @media (max-width: 900px) {
        font-size: 1.2rem;
    }

    @media (max-width: 700px) {
        font-size: 1rem;
    }
`;