import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { defaultItemModalProps } from '../../../utils/types';
export const useController = (props: any) => {
    const [modalActive, setModalActive] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [item, setItem] = useState(props.item);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [optionShowing, setOptionShowing] = useState(1);
    const inputsListRef = useRef<any>();
    const [productsAndServices, setProductsAndServices] = useState([]);
    const [commentInput, setCommentInput] = useState('');
    const [loadingItems, setLoadingItems] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState<any>([...props.item.products.map((item: any) => {
        return { ...item, image_url: `${process.env.REACT_APP_BASE_URL}app/images/default_product.jpg` };
    }), ...props.item.services.map((item: any) => {
        return {
            ...item,
            image_url: `${process.env.REACT_APP_BASE_URL}app/images/default_service.jpg`
        };
    })] ?? []);
    const [title, setTitle] = useState(props.item.title ?? '');
    const [currency, setCurrency] = useState(props.item.currency ?? 'BRL');
    const [totalValue, setTotalValue] = useState('');
    const [dueAt, setDueAt] = useState(props.item.due_at?.split("T")[0].split('-').reverse().join('/') ?? '');
    const [paidAt, setPaidAt] = useState(props.item.paid_at?.split("T")[0].split('-').reverse().join('/') ?? '');
    const modalOptions = [
        {
            key: 'comments',
            name: 'Linha do tempo'
        },
        {
            key: 'form',
            name: 'Formulário'
        },
        {
            key: 'products',
            name: 'Produtos'
        }
    ]
    useEffect(() => {
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
        props.executeOnUpdate && props.executeOnUpdate();
    }, [props.modalActive]);

    useEffect(() => {
        setTotalValue(props.item.total_value);
        if (modalActive) {
            setTimeout(() => {
                setModalShow(modalActive);
            }, 100);
        }
        else {
            setModalShow(modalActive);
        }
        props.executeOnUpdate && props.executeOnUpdate();
    }, [modalActive]);

    useEffect(() => {
        getProductsAndServices();
    }, []);

    const buildRequestObject = () => {
        let products: any = [];
        let services: any = [];

        selectedItems.forEach((item: any) => {

            if (item.product?.type === 'product') {
                products.push({
                    product: item.product,
                    quantity: item.quantity,
                    value: item.value
                });
            }
            if (item.service?.type === 'service') {
                services.push({
                    service: item.service,
                    quantity: item.quantity,
                    value: item.value
                });
            }
        });

        return {
            title: title,
            type: props.type,
            products: products,
            services: services,
            timeline: item.timeline,
            total_value: parseInt(totalValue.toString().replace(/\,/g, '.').replace(/\./g, '')) ?? 0,
            currency: currency,
            due_at: dueAt.split('/').reverse().join('-'),
            paid_at: paidAt.split('/').reverse().join('-')
        }
    }

    const sendRequest = async (closeModal?: boolean) => {
        general.api.post(props.url, buildRequestObject()).then((response: any) => {
            if (closeModal) {
                props.setModalActive((active: boolean) => !active)
                if (response?.data?.status == 403) {
                    return general.notify(response.data.message, 'error');
                }
                response.data.status === 200 ? general.notify(`${response.data.message}`, 'success') : general.notify('Erro ao tentar realizar a ação', 'error');
            };
            props.executeOnUpdate && props.executeOnUpdate();
        }).catch((error: any) => {
            console.log(error);
            general.notify('Erro ao tentar realizar a ação', 'error');
        });
    }

    const makeCommentItem = () => {
        let newTimeline: any = item.timeline;
        let accountName = general.getAccount().name.split(" ")
        newTimeline = [...newTimeline, {
            account_id: general.getAccount()._id,
            title: `${accountName[0]} ${accountName.length > 1 ? accountName[accountName.length - 1] : ''}`,
            message: commentInput,
            avatar_url: general.getAccount().avatarUrl,
            createdAt: general.getCurrentDateTime(),
            fromSystem: false
        }];
        return newTimeline.sort((a: any, b: any) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
    }

    const sendComment = async () => {
        setItem((prev: any) => ({
            ...prev, timeline: makeCommentItem()
        }))
    }

    useEffect(() => {
        if (commentInput.length > 0) {
            sendRequest(false);
            setCommentInput('');
        }
    }, [item.timeline]);

    const deleteFinancialMovement = async () => {
        general.api.delete(props.url.replace('update', 'delete')).then((response: any) => {
            props.setModalActive((active: boolean) => !active);
            if (response?.data?.status == 403) {
                return general.notify(response.data.message, 'error');
            }
            props.executeOnUpdate && props.executeOnUpdate();
        }).catch((error: any) => {
            console.log(error);
        });
    }

    const executeOnCurrencySelect = (currency: any) => {
        setCurrency(currency.value);
    }

    const handleNameChange = (e: any, id: string) => {
        document.getElementById(id)!.style.width = (e.target.value.length > 0 ? (e.target.value.length + 2) : 'Digite um nome para o campo'.length) + 'ch';
    }

    const deleteItem = (item: any) => {
        let tempItems = selectedItems;
        tempItems = tempItems.filter((tempItem: any) => tempItem._id !== item._id);
        setSelectedItems([...tempItems]);
    }

    const calculateTotalValue = () => {
        let total = 0;
        selectedItems.forEach((item: any) => {
            total += parseFloat(item.value) * parseInt(item.quantity);
        });
        setTotalValue((total / 100).toFixed(2).toString().replace('.', ','));
    }

    const executeOnItemSelect = (item: any) => {
        let tempItems = selectedItems;
        if (tempItems.find((tempItem: any) => tempItem.service?._id === item._id || tempItem.product?._id === item._id)) {
            tempItems = tempItems.map((tempItem: any) => {
                if (tempItem.service?._id === item._id || tempItem.product?._id === item._id) {
                    tempItem.quantity += 1;
                }
                return tempItem;
            });
            setSelectedItems([...tempItems]);
            return;
        };
        tempItems.push({
            [item.type]: item,
            quantity: 1,
            value: item.price,
            image_url: item.type === 'product' ? `${process.env.REACT_APP_BASE_URL}app/images/default_product.jpg` : `${process.env.REACT_APP_BASE_URL}app/images/default_service.jpg`
        });
        setSelectedItems([...tempItems]);
        calculateTotalValue();
    }

    const changeItemQuantity = (quantity: number, item: any) => {
        let tempItems = selectedItems;
        tempItems = tempItems.map((tempItem: any) => {
            if (tempItem._id === item._id) {
                tempItem.quantity = quantity < 1 ? 1 : quantity;
            }
            return tempItem;
        });
        setSelectedItems([...tempItems]);
        calculateTotalValue();
    }

    const changeItemPrice = (price: string, item: any) => {
        let tempItems = selectedItems;
        tempItems = tempItems.map((tempItem: any) => {
            if (tempItem._id === item._id) {
                tempItem.value = parseFloat(price.replace(/\./g, '').replace(/\,/g, '.')) * 100
            }
            return tempItem;
        });
        setSelectedItems([...tempItems]);
        calculateTotalValue();
    }

    const updateFinancialMovement = async () => {
        try {
            setLoading(true);
            let requestObject = buildRequestObject();
            await general.api.post('financial-movement/update', requestObject).then((response) => {
                if (response.status === 200) {
                    general.notify('Movimentação financeira atualizada com sucesso!', 'success');
                }
                setLoading(false);
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao atualizar movimentação financeira!', 'error');
            setLoading(false);
        }
    }

    const getProductsAndServices = async () => {
        let items: any = [];

        try {
            setLoadingItems(true);
            await general.api.post('product/list', {
                page: 0,
                limit: 999999999999
            }).then((response) => {
                if (response.status === 200) {
                    items = [...items, ...response.data.data.data.map((item: any) => {
                        return {
                            ...item,
                            quantity: 1,
                            type: 'product',
                            image_url: (item.image_url && item.image_url != '') ? item.image_url : `${process.env.REACT_APP_BASE_URL}app/images/default_product.jpg`
                        }
                    })
                    ];
                }
                setLoadingItems(false);
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar produtos!', 'error');
            setLoadingItems(false);
        }

        try {
            setLoadingItems(true);
            await general.api.post('service/list', {
                page: 0,
                limit: 999999999999
            }).then((response) => {
                if (response.status === 200) {
                    items = [...items, ...response.data.data.data.map((item: any) => {
                        return {
                            ...item,
                            quantity: 1,
                            type: 'service',
                            image_url: (item.image_url && item.image_url != '') ? item.image_url : `${process.env.REACT_APP_BASE_URL}app/images/default_service.jpg`
                        }
                    })
                    ];
                }
                setLoadingItems(false);
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar serviços!', 'error');
            setLoadingItems(false);
        }

        items = [...items]

        setProductsAndServices(items.sort((a: any, b: any) => a.name.localeCompare(b.name)));
    }

    useEffect(() => {
        calculateTotalValue();
    }, [selectedItems]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })
    }, []);

    return {
        general,
        modalActive,
        modalShow,
        item,
        setItem,
        handleNameChange,
        inputsListRef,
        optionShowing,
        setOptionShowing,
        sendRequest,
        deleteFinancialMovement,
        deleteConfirmationModal,
        setDeleteConfirmationModal,
        executeOnCurrencySelect,
        title,
        setTitle,
        currency,
        totalValue,
        setTotalValue,
        dueAt,
        setDueAt,
        paidAt,
        setPaidAt,
        productsAndServices,
        selectedItems,
        setSelectedItems,
        modalOptions,
        deleteItem,
        calculateTotalValue,
        executeOnItemSelect,
        changeItemQuantity,
        changeItemPrice,
        updateFinancialMovement,
        commentInput,
        setCommentInput,
        sendComment,
        loadingItems,
        loading
    };
}

export default useController;