import React, { useEffect, useState } from 'react';
import * as S from './styles';

export const Text = (props: {
    name: string;
    placeholder?: string;
    required?: boolean;
    label: string;
    value: string;
    onChange: any;
}) => {
    return (
        <S.text>
            <S.label htmlFor={props.name}>{props.label}</S.label>
            <S.textInput
                name={props.name}
                id={props.name}
                placeholder={props.placeholder ?? ''}
                defaultValue={props.value ?? ''}
                required={props.required ?? false}
                onChange={props.onChange}
                autoComplete='disabled'
            />
        </S.text>
    );
}

export default Text;