import styled, {keyframes} from 'styled-components';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';
import general from '../../../utils/general';
import useController from './controller';


export const main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    height: 400px;
    transition-duration: 0.3s;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 280px;
        margin-bottom: 55px;
    }

    @media (max-width: 900px) {
        width: 280px;
        height: 400px;
        margin-bottom: 10px;
    }

    @media (max-width: 700px) {
        height: 360px;
        flex-direction: row;
        align-items: center;
        margin-bottom: 50px;
    }
`;
export const card = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 20%;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    
`;
export const cardAvatar = styled.img`
    border-radius: 50%;
    border: 2px solid ${colors.grey};
    margin-top: -55px;
    object-fit: cover;
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 80%;

    @media (max-width: 1366px) {
        height: 77.5%;
    }
`;
export const personName = styled.div`
    font-family: ${fonts.main};
    font-size: 20px;
    color: ${colors.darkeredGrey};
    margin: 5px 0 0 0;
`;
export const personRole = styled.div`
    font-family: ${fonts.main};
    font-size: 15px;
    color: ${colors.darkerGrey};
    margin-bottom: 5px;
`;
export const cardInfo = styled.div`
    display: flex;
    height: 220px;
    width: calc(100% - 20px);
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 0 10px 0 10px;
    margin: 5px 0 5px 0;

    @media (max-width: 900px) {
        height: 220px;
    }
`;
export const personInfo = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.grey};
`;
export const personInfoName = styled.div`
    width: 30%;
    text-align: left;
    font: 600 12px ${fonts.main};
    color: ${colors.black};
`;
export const personInfoValue = styled.div`
    width: 60%;
    margin-left: 10%;
    direction: rtl;
    text-align: right;
    font: 400 12px ${fonts.main};

    @media (max-width: 1366px) {
        width: 50%;
        margin-left: 20%;
    }
    @media (max-width: 900px) {
        width: 60%;
        margin-left: 10%;
    }
    
    span {
        direction: ltr;
        unicode-bidi: embed;
    }
`;
export const noInfo = styled.p`
    font: 400 16px ${fonts.main};
    text-transform: uppercase;
    text-align: center;
    color: ${colors.black};
    margin-top: 10px;
    width: 70%;
    align-self: center;
    margin-top: 60px;

    @media (max-width: 1366px) {
        margin-top: 20px;
        font: 400 14px ${fonts.main};
    }

    @media (max-width: 900px) {
        margin-top: 60px;
        font: 400 16px ${fonts.main};
    }
`;