import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [services, setServices] = useState<any>([]);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [categories, setCategories] = useState<any>([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [serviceCreateTemplate, setServiceCreateTemplate] = useState({
        title: 'Novo Serviço',
        subtitle: 'Crie um novo serviço',
        infos: [
            {
                key: 'name',
                name: 'Nome',
                type: "text",
                value: '',
                disabled: true,
                required: true
            },
            {
                key: 'price',
                name: 'Preço',
                type: "money",
                value: '0,00',
                disabled: true,
                required: true
            },
            {
                key: 'description',
                name: 'Descrição',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'sells',
                name: 'Vendas de serviço',
                type: "number",
                value: '0',
                disabled: true
            },
            {
                key: 'discount',
                name: 'Desconto',
                type: "number",
                value: '',
                disabled: true
            },
            {
                key: 'observation',
                name: 'Observação',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'category_id',
                name: 'Categoria',
                type: "select",
                value: '',
                disabled: true,
                required: true,
                selectOptions: []
            },
        ]
    })

    const getServices = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('service/list', {
                page: page
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setServices(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar serviços', 'error');
            setLoading(false);
        }
    }

    const getCategories = async () => {
        try {
            const response = await general.api.get('item-category/list/2');
            if (response.status === 200) {
                if(response.data.status == 403) {
                    return;
                }
                let categories = response.data.data.map((item: any) => {
                    return {
                        name: item.name,
                        value: item._id
                    }
                });
                setCategories(categories);
                serviceCreateTemplate.infos[6].selectOptions = categories;
                return categories;
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar categorias', 'error');
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            newCurrentItem.infos = newCurrentItem.infos.map((info: any) => {
                if (info.type == 'select') {
                    Promise.resolve(getCategories()).then((categories) => {
                        info.selectOptions = categories;
                    });
                }
                return info;
            })
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getServices(true);
    }, [page]);

    useEffect(() => {
        getServices(true);
        getCategories();
    }, []);

    useEffect(() => {
        getServices(false);
        getCategories();
    }, [createModalActive, editModalActive]);

    return {
        general,
        services,
        createModalActive,
        setCreateModalActive,
        editModalActive,
        setEditModalActive,
        serviceCreateTemplate,
        currentItem,
        setCurrentItem,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock
    }
}

export default useController;