import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import useController from './controller';
import Avatar from 'react-avatar';

export const main = styled.div<{ active: boolean, animationOpacity: boolean }>`
    display: flex;
    width: ${props => props.active ? '100vw' : '0px'};
    height: ${props => props.active ? '100vh' : '0px'};
    opacity: ${props => props.animationOpacity ? 1 : 0};
    transition-duration: 0.3s;
    transition-property: opacity;
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    z-index: 999;
    justify-content: center;
    align-items: center;
`;
export const modal = styled.div<{ animationInOut: boolean }>`
    width: 40%;
    max-height: 75%;
    display: flex;
    flex-direction: row;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 20px 5px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    opacity: ${props => props.animationInOut ? 1 : .5};
    transform: ${props => props.animationInOut ? 'translateY(0px)' : 'translateY(100px)'};
    transition-duration: 0.3s;

    @media(max-width: 1200px){
        width: 50%;
        margin-top: -5%;
    }
    @media(max-width: 800px){
        width: 75%;
        margin-top: -10%;
    }
    @media(max-width: 500px){
        max-height: 80%;
        width: 90%;
        margin-top: -20%;
        padding-top: 5px;
        flex-direction: column;
    }
`;
export const containerForm = styled.form<{ showItem: 'form' | 'comments' }>`
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    width: 100%;
    max-height: 100%;

    @media(max-width: 600px){
        width: 90%;
        max-height: 95%;
        padding: 0 15px;
    }
`;
export const title = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;

    @media(max-width: 500px){
        margin: 20px 0;
    }
`;
export const allFormInputs = styled.div`
    display: grid;
    width: 100%;
    max-height: 75%;
    overflow-y: auto;
    gap: 10px;
    padding: 5px 5px 10px 0;
    place-items: center;

    @media(max-width: 1366px){
        max-height: 78%;
    }

    @media(max-width: 600px){
        margin-bottom: 5px;
        width: 100%;
        max-height: 400px;
        place-items: start;
    }
`;
export const inputContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    background-color: ${colors.backgroundWhite};
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey};

    :focus-within{
        transform: scale(1.03);
    }

    @media(max-width: 1366px){
        width: 94%;
    }

    @media(max-width: 1200px){
        width: 95%;
    }

    @media(max-width: 500px){
        width: 94%;
        align-self: center;
        justify-self: center;
        :focus-within{
            transform: scale(1.025);
        }
    }
`;
export const input = styled.input<{ width?: number }>`
    display: flex;
    height: 20px;
    width: ${props => props.width ? props.width + 'ch' : 'auto'};
    border: none;
    outline: none;
    background-color: transparent;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    color: ${props => props.disabled ? colors.grey : colors.black};

    :nth-child(1){
        font-weight: 600;
    }
    :nth-child(2){
        flex: 1;
        text-align: end;
        width: 100%;
    }
`;
export const avatar = styled.img``;
export const defaultAvatar = styled(Avatar)`
    transform: scale(1);
    @media(max-width: 1366px){
        transform: scale(0.9);
    }
`;
export const options = styled.div`
    overflow-y: auto;
    flex: 1;

    @media(max-width: 500px){
        height: 80%;
    }
`;
export const uniqueOption = styled.p<{ mobileOption?: boolean }>`
    width: 150px;
    min-height: 35px;
    display: ${props => props.mobileOption ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: transform;
    font-family: ${fonts.main};
    font-weight: 800;
    font-size: 14px;
    color: ${colors.linkBlue};
    text-align: center;

    :hover{
        text-decoration: underline;
    }

    @media(max-width: 800px){
        ${props => props.mobileOption && css`
            display: flex;
        `}
    }
    @media(max-width: 1366px){
        min-height: 25px;
    }
`;
export const button = styled.button`
    width: 80%;
    height: 35px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid ${colors.black};
    cursor: pointer;
    color: ${colors.black};
    align-self: center;
    margin-top: 10px;
    font-family: ${fonts.main};
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;

    :hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }

    @media(max-width: 1366px){
        width: 95%;
        height: 100px;
    }
    
    @media(max-width: 500px){
        width: 96%;
        height: 30px;
    }
`;