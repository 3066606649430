import React, { useEffect, useState } from 'react';
import * as S from './styles';

export const TextArea = (props: {
    name: string;
    placeholder?: string;
    required?: boolean;
    label: string;
    value: string;
    onChange: any;
    hasModules?: boolean;
}) => {
    return (
        <S.text>
            <S.label htmlFor={props.name}>{props.label}</S.label>
            <S.textArea
                defaultValue={props.value}
                onChange={props.onChange}
                contentEditable
            />
        </S.text>
    );
}

export default TextArea;