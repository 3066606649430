import general from '../../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { FormType } from './enum'

export const useController = (props: any) => {
    const [form, setForm] = useState<any>({
        title: '',
        description: '',
        thanks_active: false,
        form_type: FormType.SURVEY,
        thanks_message: '',
        thanks_btn_title: '',
        thanks_btn_link: '',
        fields_array: [],
        schedule: {
            allowed_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
            time_in_minutes: 15,
            time_day_start: '07:00',
            time_day_end: '18:00',
        },
    });
    const [lastFieldCreatedId, setLastFieldCreatedId] = useState('');
    const [loading, setLoading] = useState(false);

    const saveForm = async () => {
        if(!verifySendRequest()) return;
        setLoading(true);
        general.api.post(props.type == 'create' ? 'form/create' : `form/update/${form._id}`, form).then((response: any) => {
            if(response?.data?.status == 403){
                return general.notify(response.data.message, 'error');
            }
            response.data.status === 200 ? general.notify(`${props.title ? props.title + ' ' : ''}${response.data.message.toLowerCase()}`, 'success') : general.notify(`Erro ao tentar realizar a ação - [${response.data.message}]`, 'error');
            cleanForm();
        }).catch((error: any) => {
            general.notify('Erro ao tentar realizar a ação', 'error');
            console.log(error);
            setLoading(false);
        }).then(() => {
            props.setModalActive(false);
            setLoading(false);
        });
    }

    const addField = () => {
        const id = general.randomString(10);
        setForm({ ...form, fields_array: [...form.fields_array, {
            id: id,
            title: '',
            type: 'text',
            options: [],
            allowed_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
        }] });
        setLastFieldCreatedId(id);
    }

    useEffect(() => {
        document.getElementById(lastFieldCreatedId)?.scrollIntoView({ behavior: 'smooth' });
    }, [lastFieldCreatedId]);

    const removeField = (index: number) => {
        const fields = form.fields_array;
        fields.splice(index, 1);
        setForm({ ...form, fields_array: fields });
    }

    const duplicateField = (index: number) => {
        const fields = form.fields_array;
        const newField = {...fields[index], id: general.randomString(10), title: fields[index].title + ' (Cópia)'};
        fields.push(newField);
        setForm({ ...form, fields_array: fields });

        setLastFieldCreatedId(newField.id);
    }

    const setFieldRequired = (index: number) => {
        const fields = form.fields_array;
        fields[index].required = !fields[index].required;
        setForm({ ...form, fields_array: fields });
    }

    const verifySendRequest = () => {
        if(form.title == ''){
            general.notify('Preencha os campos obrigatórios', 'error');
            return false;
        }
        if(form.fields_array.length == 0 && (form.form_type != FormType.CLIENT || form.form_type != FormType.SUPPLIER || form.form_type != FormType.EMPLOYEE)){
            general.notify('Adicione pelo menos um campo ao formulário', 'error');
            return false;
        }
        if(form.form_type == FormType.SCHEDULE && form.schedule.allowed_days.length == 0){
            general.notify('Selecione pelo menos um dia para o agendamento', 'error');
            return false;
        }
        return true;
    }

    const cleanForm = () => {
        setForm({
            id: '',
            title: '',
            description: '',
            thanks_active: false,
            form_type: FormType.SURVEY,
            thanks_message: '',
            thanks_btn_title: '',
            thanks_btn_link: '',
            fields_array: [],
            schedule: {
                allowed_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
                time_in_minutes: 15,
                time_day_start: '07:00',
                time_day_end: '18:00',
            },
        });
        setLastFieldCreatedId('');
    }

    useEffect(() => {
        if(props.type == 'edit'){
            setForm((form: any) => ({ ...form, ...props.currentItem }));
        }
    }, [props.currentItem]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })
    }, []);
    
    const toggleDay = (day: string) => {
        const days = form.schedule.allowed_days;
        if(days.includes(day)){
            days.splice(days.indexOf(day), 1);
        } else {
            days.push(day);
        }
        setForm({ ...form, schedule: { ...form.schedule, allowed_days: days } });
    }

    const toggleFieldDay = (fieldIndex: number, day: string) => {
        const days = form?.fields_array[fieldIndex]?.allowed_days ?? [];
        if(days?.includes(day)){
            days?.splice(days.indexOf(day), 1);
        } else {
            days?.push(day);
        }
        const fields = form?.fields_array;
        fields[fieldIndex].allowed_days = days;
        setForm({ ...form, fields_array: fields });
    }

    return {
        general,
        form,
        setForm,
        saveForm,
        addField,
        removeField,
        duplicateField,
        setFieldRequired,
        verifySendRequest,
        cleanForm,
        toggleDay,
        toggleFieldDay,
        loading,
        FormType
    }
}

export default useController;