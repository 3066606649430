import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemComponent from '../../../components/app/NewItemComponent';
import CardProductService from '../../../components/app/CardProductService';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import Pagination from '../../../components/general/Pagination';

const Services = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Serviços' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`service/create`} canAddCustomFields={false} title='Serviço' type={controller.general.enums.itemTypes.SERVICE} item={controller.serviceCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`service/update/${controller.currentItem._id}`} canAddCustomFields={false} type={controller.general.enums.itemTypes.SERVICE} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Serviços' />
                {controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                    <S.operationsArea>
                        <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(prev => !prev)}>
                            <NewItemComponent mainIcon='faScrewdriverWrench' pageItem='Serviço' />
                        </div>
                        {(controller.services ?? []).map((item: any, index: number) => {
                            return (
                                <div className='content-cards-wrapper' key={`service_${index}`} onClick={() => {
                                    controller.setCurrentItem(item)
                                    controller.setEditModalActive(active => !active)
                                }}>
                                    <CardProductService
                                        id={item.id}
                                        key={index}
                                        title={item.name}
                                        image={(item.image_url && item.image_url != '') ? item.image_url : `${process.env.REACT_APP_BASE_URL}app/images/default_service.jpg`}
                                        info={[
                                            {
                                                icon: 'faScrewdriverWrench',
                                                text: item.sells ?? 0,
                                                description: `Quantidade de serviços realizados`
                                            }
                                        ]}
                                        itemsAmount={item.sells ?? 0}
                                        unitPrice={item.price}
                                        sellValueAmount={item.sellValueAmount ?? 0}
                                        sellsAmount={item.sells ?? 0}
                                    />
                                </div>
                            );
                        })
                        }
                    </S.operationsArea>
                )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    )
}

export default Services;