import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemComponent from '../../../components/app/NewItemComponent';
import CardForm from '../../../components/app/CardForm';
import Pagination from '../../../components/general/Pagination';
import FormModal from './FormModal';

const Forms = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Formulários' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <FormModal type='create' modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} />
            <controller.general.DefaultLoading active={controller.loading} />
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Formulários' />
                {controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                    <S.operationsArea>
                        <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                            <NewItemComponent mainIcon='faPaste' pageItem='Formulário'>
                                Criar <br /> formulário
                            </NewItemComponent>
                        </div>
                        {(controller.forms ?? []).map((item: any, index) => {
                            return (
                                <div className='content-cards-wrapper' onClick={() => {
                                    controller.general.GoToRoute(`/app/form/options/${item.form_slug}`)
                                }} key={`supplier_${index}`}>
                                    <CardForm title={item.title ?? 'Não informado'}
                                    image={`${process.env.REACT_APP_BASE_URL}app/images/default_form.jpg`}
                                    info={[{
                                        icon: 'faFileSignature',
                                        text: (item.answers.length).toString(),
                                        description: 'Quantidade de respostas'
                                    },{
                                        icon: 'faEye',
                                        text: (item.view_count ?? 0).toString(),
                                        description: 'Quantidade de visualizações'
                                    }]} />
                                </div>
                            )
                        })}
                    </S.operationsArea>
                )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Forms;