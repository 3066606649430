import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultItemModalProps } from '../../../utils/types';
import Avatar from 'react-avatar';
import { colors } from '../../../utils/colors';
import general from '../../../utils/general';
import SelectWithSearch from '../Inputs/SelectWithSearch';

const UpdateFinancialMovementModal = (props: any) => {
    const controller = useController(props);
    return (
        <S.main active={controller.modalShow} animationOpacity={controller.modalShow} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.confirmDeleteModal active={controller.deleteConfirmationModal} onClick={(e) => {
                e.stopPropagation()
                controller.setDeleteConfirmationModal(false)
            }}>
                <S.confirmDeleteModalContent onClick={(e) => e.stopPropagation()}>
                    <S.confirmDeleteModalTitle>Deletar item</S.confirmDeleteModalTitle>
                    <S.confirmDeleteModalText>Tem certeza que deseja deletar este item?</S.confirmDeleteModalText>
                    <S.confirmDeleteModalButtons>
                        <S.confirmDeleteModalButton onClick={() => controller.deleteFinancialMovement()}>Sim</S.confirmDeleteModalButton>
                        <S.confirmDeleteModalButton onClick={() => controller.setDeleteConfirmationModal(false)}>Não</S.confirmDeleteModalButton>
                    </S.confirmDeleteModalButtons>
                </S.confirmDeleteModalContent>
            </S.confirmDeleteModal>

            <S.modal onClick={(e) => e.stopPropagation()} animationInOut={controller.modalShow}>
                <S.container>
                    <S.title>Opções</S.title>
                    {controller.item.avatar ? (<S.avatar src={props.item.avatar} />) : (<S.defaultAvatar textSizeRatio={3} maxInitials={2} round='5px' size='150px' name={controller.item.title ?? controller.item.name} style={{
                        alignSelf: 'center',
                    }} />)}
                    <S.options>
                        <S.uniqueOption color={controller.general.utilColors.red} onClick={() => controller.setDeleteConfirmationModal(true)}>Deletar Item</S.uniqueOption>
                        <S.uniqueOption color={controller.general.utilColors.linkBlue} mobileOption={true} onClick={() => controller.setOptionShowing(prev => prev < (controller.modalOptions.length - 1) ? prev + 1 : 0)}>Ver {(controller.optionShowing == (controller.modalOptions.length - 1) ? controller.modalOptions[0].name : controller.modalOptions[controller.optionShowing + 1].name).toLowerCase()}</S.uniqueOption>
                        <S.uniqueOption color={controller.general.utilColors.linkBlue} pcOption={true} onClick={() => controller.setOptionShowing(prev => prev == 0 ? 1 : 0)}>Ver {(controller.optionShowing == (controller.modalOptions.length - 1) ? controller.modalOptions[0].name : controller.modalOptions[controller.optionShowing + 1].name).toLowerCase()}</S.uniqueOption>
                        <S.uniqueOption color={controller.general.utilColors.linkBlue} onClick={() => props.setModalActive((active: boolean) => !active)}>Fechar</S.uniqueOption>
                    </S.options>
                </S.container>
                <S.containerForm showItem={controller.modalOptions[controller.optionShowing].key} onSubmit={(e) => {
                    e.preventDefault();
                    controller.sendRequest(true);
                }}>
                    <S.title>Informações</S.title>
                    <S.inputsContainer>
                        <S.itemInput>
                            <S.itemInputLabel>Título*:</S.itemInputLabel>
                            <S.itemInputField value={controller.title} onChange={(e) => controller.setTitle(e.target.value)} />
                        </S.itemInput>
                        <S.itemInput>
                            <S.itemInputLabel>Moeda*:</S.itemInputLabel>
                            <div className='selectWithSearchCurrency'>
                                <SelectWithSearch defaultValue='BRL (R$)' executeOnSelect={controller.executeOnCurrencySelect} options={
                                    controller.general.getCountries().reduce((countries: any, item: any) => {
                                        if (item.currency != null && item.currency != undefined && item.currency != '') {
                                            if (!countries.find((country: any) => country.value == item.currency)) {
                                                countries.push({
                                                    name: `${item.currency} (${item.currencySymbol})`,
                                                    value: item.currency
                                                });
                                            }
                                        }
                                        return countries;
                                    }, []).sort((a: any, b: any) => a.name.localeCompare(b.name))} />
                            </div>
                        </S.itemInput>
                        <S.itemInput>
                            <S.itemInputLabel>Valor total*:</S.itemInputLabel>
                            <S.itemInputField value={controller.general.formatMoney(controller.totalValue.toString())} onChange={(e) => controller.setTotalValue(e.target.value)} />
                        </S.itemInput>
                        <S.itemInput>
                            <S.itemInputLabel>Data prevista (opcional):</S.itemInputLabel>
                            <S.itemInputField value={controller.general.customMask(controller.dueAt, "##/##/####", '#', true)} onChange={(e) => controller.setDueAt(e.target.value)} />
                        </S.itemInput>
                        <S.itemInput>
                            <S.itemInputLabel>Data de pagamento (opcional):</S.itemInputLabel>
                            <S.itemInputField value={controller.general.customMask(controller.paidAt, "##/##/####", '#', true)} onChange={(e) => controller.setPaidAt(e.target.value)} />
                        </S.itemInput>
                    </S.inputsContainer>
                    <S.button>
                        <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={15}/>
                        Salvar
                    </S.button>
                </S.containerForm>
                <S.container showItem={controller.modalOptions[controller.optionShowing].key}>
                    <SelectWithSearch type="withDescription" clearOnClick executeOnSelect={controller.executeOnItemSelect} options={controller.productsAndServices} />
                    {controller.selectedItems.length > 0 ?
                        <S.movementItemsContainer>
                            {(controller.selectedItems ?? []).map((item: any) => (
                                <S.movementItem key={controller.general.randomString(20)}>
                                    <S.itemImage src={item.image_url} />
                                    <S.itemContainer>
                                        <S.itemInfos>
                                            <S.itemTitle>{item.product?.name ?? item.service?.name ?? "Não informado"}</S.itemTitle>
                                            <S.deleteItemIconMobile onMouseDown={() => controller.deleteItem(item)} icon={controller.general.icons.faTrash} />
                                            <S.itemDescription>{item.product?.description ?? item.service?.description ?? "Não informado"}</S.itemDescription>
                                        </S.itemInfos>
                                        <S.itemInputs>
                                            <S.itemInput>
                                                <S.itemInputLabel>Quantidade:</S.itemInputLabel>
                                                <S.itemInputField defaultValue={item.quantity} type='number' onBlur={(e) => controller.changeItemQuantity(parseInt(e.target.value), item)} />
                                            </S.itemInput>
                                            <S.itemInput>
                                                <S.itemInputLabel>Valor unitário:</S.itemInputLabel>
                                                <S.itemInputField defaultValue={controller.general.formatMoney((item.value ?? 0).toString())} onBlur={(e) => controller.changeItemPrice(e.target.value, item)} onChange={(e) => e.target.value = controller.general.formatMoney(e.target.value)} />
                                            </S.itemInput>
                                        </S.itemInputs>
                                    </S.itemContainer>
                                    <S.deleteItemIcon onMouseDown={() => controller.deleteItem(item)} icon={controller.general.icons.faTrash} />
                                </S.movementItem>
                            ))}
                            <S.button onClick={() => {controller.sendRequest(true)}}>
                                <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={15}/>
                                Salvar
                            </S.button>
                        </S.movementItemsContainer>
                        :
                        <S.noItemsContainer>
                            <S.noItemsIcon icon={controller.general.icons.faExclamationTriangle} />
                            <S.noItemsTitle>Nenhum item selecionado</S.noItemsTitle>
                            <S.noItemsDescription>Selecione um item para adicionar à movimentação financeira</S.noItemsDescription>
                        </S.noItemsContainer>
                    }
                </S.container>
                <S.container showItem={controller.modalOptions[controller.optionShowing].key}>
                    <S.commentSection>
                        <S.title>Comentários</S.title>
                        <S.allComments>
                            {(controller.item?.timeline ?? []).length > 0 ?
                                (controller.item?.timeline ?? []).map((item: any, index: number) => (
                                    <S.comment key={`comment_${index}`}>
                                        <S.commentHeader>
                                            {item.avatar_url || item.fromSystem ? <S.commentAvatar src={item.fromSystem ? `${process.env.REACT_APP_BASE_URL}app/images/logo/logo_arezdot_icon.ico` : item.avatar_url} /> : <Avatar textSizeRatio={2.25} round='20px' size='40px' name={item.title} style={{ alignSelf: 'center' }} />}
                                            <S.commentItemInfo>
                                                <S.commentName>{item.title}</S.commentName>
                                                <S.commentDate>{`${item.createdAt.split('T')[0].split('-').reverse().join('/')} ${item.createdAt.split('T')[1].substring(0, 5)}`}</S.commentDate>
                                            </S.commentItemInfo>
                                        </S.commentHeader>
                                        <S.commentContent>{item.message}</S.commentContent>
                                    </S.comment>
                                ))
                                :
                                <S.noComments>
                                    <S.noCommentsIcon icon={general.icons.faCommentSlash} />
                                    <S.noCommentsText>Nenhum comentário</S.noCommentsText>
                                </S.noComments>
                            }
                        </S.allComments>
                        <S.sendCommentSection onSubmit={() => controller.sendComment()}>
                            <S.commentToSendInput onKeyDown={(e: any) => {
                                if (e.key == 'Enter' && controller.commentInput.trim() != '' && !e.shiftKey) {
                                    e.preventDefault();
                                    controller.sendComment();
                                }
                            }} value={controller.commentInput} onChange={(e) => controller.setCommentInput(e.target.value)} placeholder='Escreva um comentário...' />
                            <S.commentSendButton onClick={() => controller.sendComment()} className='sendCommentBtn' icon={general.icons.faChevronRight} />
                        </S.sendCommentSection>
                    </S.commentSection>
                </S.container>
            </S.modal>
        </S.main>
    );
}

export default UpdateFinancialMovementModal;