import general from '../../../utils/general';
import { useState, useRef } from 'react';
export const useController = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    return {
        general,
        isExpanded,
        setIsExpanded
    };
}

export default useController;