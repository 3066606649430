import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import ActionCard from '../../../components/app/ActionCard';
import UserCard from '../../../components/app/UserCard';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import Pagination from '../../../components/general/Pagination';

const Stakeholders = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Clientes' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`stakeholder/create/1`} canAddCustomFields title='Cliente' type={controller.general.enums.itemTypes.EMPLOYEE} item={controller.personCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`stakeholder/update/${controller.currentItem._id}`} canAddCustomFields type={controller.general.enums.itemTypes.EMPLOYEE} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} hasTimeline={true} /> : null}
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Clientes' />
                {controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                    <S.operationsArea>
                        <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                            <ActionCard mainIcon='faUser' pageItem='Cliente'>
                                Adicionar <br /> cliente
                            </ActionCard>
                        </div>
                        {(controller.stakeholders ?? []).map((item: any, index) => {
                            return (
                                <div className='content-cards-wrapper' onClick={() => {
                                    controller.setCurrentItem(item)
                                    controller.setEditModalActive(active => !active)
                                }} key={`stakeholders_${index}`}>
                                    <UserCard title={item.infos?.filter((info: any) => info.key == 'name')[0]?.value ?? 'Não informado'} subtitle={item.createdAt.split('T')[0].split('-').reverse().join('/')} infos={item.infos} />
                                </div>
                            )
                        })}
                    </S.operationsArea>
                )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Stakeholders;