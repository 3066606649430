import styled from 'styled-components';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1200px) {
        height: calc(100% - 60px);
    }
`;
export const operationsArea = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-template-rows: auto 1fr;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;
    justify-content: center;

    &::after {
        content: '';
        flex: auto;
    }

    @media (max-width: 1366px) {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`;