export enum itemTypes {
    CATEGORY = 0,
    PRODUCT = 1,
    SERVICE = 2,
    FINANCIAL_MOVEMENT = 3,
    EMPLOYEE = 4,
    CLIENT = 5,
    SUPPLIER = 6,
    ACCOUNT = 7,
    CUPOM = 8,
    PLAN = 9,
    OTHER = 999
}

export default {
    itemTypes
}