import general from '../../../../utils/general';
import { useEffect, useState } from 'react';

export const useController = (props: any) => {
    const [optionsShowing, setOptionsShowing] = useState(false);
    const [options, setOptions] = useState(props.options ?? []);
    const [tempOptions, setTempOptions] = useState(props.options ?? []);
    const [searchInput, setSearchInput] = useState(props.defaultValue ?? '');

    const handleSelectOption = (option: any) => {
        props.executeOnSelect(option);
        setSearchInput(props.clearOnClick ? '' : (option.name ?? option.title ?? 'Não informado'));
        setTempOptions(options);
        setOptionsShowing(false);
    }

    const handleSearchInputChange = (e: any) => {
        setSearchInput(e.target.value);
        setTempOptions(options.filter((option: any) => option.name.toLowerCase().includes(e.target.value.toLowerCase())));
    }

    useEffect(() => {
        setOptions(props.options ?? []);
        setTempOptions(props.options ?? []);
        setSearchInput(props.defaultValue ?? '');
    }, [props.options]);

    return {
        options,
        tempOptions,
        optionsShowing,
        setOptionsShowing,
        handleSelectOption,
        handleSearchInputChange,
        searchInput,
        general
    };
}

export default useController;