import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import general from '../../../../utils/general';

const SelectWithSearch = (props: { options: any, executeOnSelect: Function, type?: "simple" | "withDescription", clearOnClick?: boolean, defaultValue?: string, loading?: boolean, placeholder?: string }) => {
    const controller = useController(props);

    return (
        <S.mainContainer>
            <S.searchContainer showOptionsContainer={controller.optionsShowing}>
                <S.searchInput onFocus={() => controller.setOptionsShowing(true)} onBlur={() => controller.setOptionsShowing(false)} value={controller.searchInput} placeholder={props.placeholder ?? 'Pesquisar...'} onChange={(e: any) => controller.handleSearchInputChange(e)} />
                <S.searchIcon icon={general.icons.faSearch} />
            </S.searchContainer>
            <S.optionsContainer showOptionsContainer={controller.optionsShowing}>
                <controller.general.DefaultLoadingFit active={props.loading ?? false} borderRadius={5}/>
                {controller.tempOptions.length > 0 ?
                    controller.tempOptions.map((option: any) => (
                        <>
                            {props.type === "withDescription" ?
                                <S.option key={general.randomString(20)} onMouseDown={() => { controller.handleSelectOption(option) }}>
                                    <S.optionTitle>
                                        {option.name ?? option.title ?? 'Não informado'}
                                    </S.optionTitle>
                                    <S.optionDescription>
                                        Descrição: {option.description.trim() ? option.description : 'Não informada'}
                                    </S.optionDescription>
                                </S.option>
                                :
                                <S.option key={general.randomString(20)} onMouseDown={() => { controller.handleSelectOption(option) }}>
                                    <S.optionTitle>
                                        {option.name ?? option.title ?? 'Não informado'}
                                    </S.optionTitle>
                                </S.option>
                            }
                        </>
                    ))
                    :
                    <S.noOptions>
                        <S.noOptionsIcon icon={general.icons.faExclamationCircle} />
                        <S.noOptionsText>Nenhuma opção encontrada</S.noOptionsText>
                    </S.noOptions>
                }
            </S.optionsContainer>
        </S.mainContainer>
    );
}

export default SelectWithSearch;