import general from '../../../utils/general';
import { useEffect, useState } from 'react';

export const useController = () => {
    //filter starting date is the start of the month and endDate is the current date
    const [filters, setFilters] = useState({
        moneyIn: true,
        moneyOut: true,
        startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0],
        endDate: new Date().toISOString().split('T')[0],
        minValue: null,
        maxValue: null
    });

    const [currentItem, setCurrentItem] = useState<any>();
    const [createCompleteModalActive, setCreateCompleteModalActive] = useState(false);
    const [updateCompleteModalActive, setUpdateCompleteModalActive] = useState(false);
    const [financialMovements, setFinancialMovements] = useState([]);
    const [totalMoneyIn, setTotalMoneyIn] = useState(0);
    const [totalMoneyOut, setTotalMoneyOut] = useState(0);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const financialMovementCreateTemplate = {
        title: 'Nova Cateforia',
        subtitle: 'Crie uma nova categoria de produto ou serviço',
        infos: [
            {
                key: 'title',
                name: 'Título',
                type: "text",
                value: '',
                disabled: true
            },
            {
                key: 'type',
                name: 'Tipo de movimentação',
                type: "select",
                value: '',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Entrada',
                        value: '1'
                    },
                    {
                        name: 'Saída',
                        value: '0'
                    }
                ]
            },
            {
                key: 'currency',
                name: 'Moeda',
                type: "select",
                value: 'BRL',
                disabled: true,
                selectOptions: general.getCountries().reduce((countries: any, item: any) => {
                    if (item.currency != null && item.currency != undefined && item.currency != '') {
                        if (!countries.find((country: any) => country.value == item.currency)) {
                            countries.push({
                                name: `${item.currency} (${item.currencySymbol})`,
                                value: item.currency
                            });
                        }
                    }
                    return countries;
                }, []).sort((a: any, b: any) => a.name.localeCompare(b.name))
            },
            {
                key: 'total_value',
                name: 'Valor',
                type: "money",
                value: '',
                disabled: true
            },
            {
                key: 'due_at',
                name: 'Data prevista',
                type: "text",
                mask: "##/##/####",
                value: '',
                disabled: true
            },
            {
                key: 'paid_at',
                name: 'Pago em',
                type: "text",
                mask: "##/##/####",
                value: '',
                disabled: true
            },
        ]
    }

    const getFinancialMovements = async (showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            await general.api.post('financial-movement/list', {
                ...filters,
                page: page
            }).then((response) => {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                if (response.status === 200) {
                    const data = response.data.data;
                    setTotalPages(data.total_pages);
                    setFinancialMovements(data.financialmovements);
                    setTotalMoneyIn(data.total_income);
                    setTotalMoneyOut(data.total_expense);
                    setLoading(false);
                }
            });
        }
        catch (e) {
            general.notify('Erro ao buscar movimentações financeiras', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        getFinancialMovements(true);
    }, [page]);

    useEffect(() => {
        getFinancialMovements(true);
    }, []);

    useEffect(() => {
        getFinancialMovements(false);
    }, [createCompleteModalActive, currentItem]);

    useEffect(() => {
        getFinancialMovements(true);
    }, [filters]);

    return {
        general,
        filters,
        setFilters,
        financialMovementCreateTemplate,
        currentItem,
        setCurrentItem,
        createCompleteModalActive,
        setCreateCompleteModalActive,
        financialMovements,
        totalMoneyIn,
        totalMoneyOut,
        getFinancialMovements,
        updateCompleteModalActive,
        setUpdateCompleteModalActive,
        page,
        setPage,
        totalPages,
        setTotalPages,
        loading,
        permissionBlock
    }
}

export default useController;