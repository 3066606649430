import React, { useEffect, useState } from 'react';
import * as S from './styles';
import useController from './controller';
import DefaultLoading from '../../../../components/app/DefaultLoading';
import { useGlitch } from 'react-powerglitch'
import { Text } from '../../../../components/general/inputs/Text';
import { TextArea } from '../../../../components/general/inputs/TextArea';
import { StyledCheckbox as Checkbox } from '../../../../components/general/inputs/StyledCheckbox';
import { Select } from '../../../../components/general/inputs/Select';
import { Calendar } from '../../../../components/general/inputs/Calendar';
import { Toaster } from 'sonner';

const PublicForm = () => {
    const controller = useController();
    const glitch = useGlitch();

    return (
        <S.main>
            <Toaster richColors />
            {controller.form ? (
                <S.formWrapper formSended={controller.formSended}>
                    {controller.formSended ? (
                        <S.formSended>
                            <div ref={glitch.ref}>
                                <S.formSendedIcon icon={controller.general.icons.faCheckCircle} />
                            </div>
                            <S.formSendedTitle ref={glitch.ref}>
                                {controller.form?.thanks_message != '' ? controller.form?.thanks_message : 'Formulário enviado com sucesso!'}
                                <br />
                                {controller.form?.redirect_to && `Redirecionando em ${controller.redirectCounter} segundo${controller.redirectCounter > 1 ? 's' : ''}...`}
                            </S.formSendedTitle>
                            {(controller.form?.thanks_btn_link != '' && controller.form?.thanks_active) && (
                                <S.formSendedButton onClick={() => window.location.href = controller.form?.thanks_btn_link}>
                                    <S.redirectButton>{(controller.form?.thanks_btn_title != '') ? controller.form?.thanks_btn_title : 'Prosseguir'}</S.redirectButton>
                                </S.formSendedButton>
                            )}
                        </S.formSended>
                    ) : (
                        <>
                        <S.formTitle>
                        {controller.form.title}
                    </S.formTitle>
                    <S.formDescription dangerouslySetInnerHTML={{ __html: controller.form.description }} />
                    <S.formFields>
                        {controller.form.fields_array.map((field: any, index: number) => (
                            <S.formField key={`field_${index}`}>
                                {field.type == 'text' && (
                                    <Text
                                        name={field.id}
                                        placeholder={field.title + (field.required ? ' *' : '')}
                                        required={field.required}
                                        label={field.title + (field.required ? ' *' : '')}
                                        value={controller.answer.fields.filter((x: any) => x.id == field.id)[0].value}
                                        onChange={(e: any) => controller.changeField(field.id, e.target.value)}
                                    />
                                )}
                                {field.type == 'textarea' && (
                                    <TextArea
                                        name={field.id}
                                        placeholder={field.title + (field.required ? ' *' : '')}
                                        required={field.required}
                                        label={field.title + (field.required ? ' *' : '')}
                                        value={controller.answer.fields.filter((x: any) => x.id == field.id)[0].value}
                                        onChange={(e: any) => controller.changeField(field.id, e.target.value)}
                                        hasModules={false}
                                    />
                                )}
                                {field.type == 'checkbox' && (
                                    <Checkbox
                                        name={field.id}
                                        required={field.required}
                                        label={field.title}
                                        checked={controller.answer.fields.filter((x: any) => x.id == field.id)[0].value}
                                        onChange={(e: any) => controller.changeField(field.id, e)}
                                    />
                                )}
                                {field.type == 'select' && (
                                    <Select
                                        name={field.id}
                                        required={field.required}
                                        label={field.title + (field.required ? ' *' : '')}
                                        value={controller.answer.fields.filter((x: any) => x.id == field.id)[0].value}
                                        onChange={(e: any) => controller.changeField(field.id, e.target.value)}
                                        options={field.options}
                                    />
                                )}
                                {field.type == 'date' && (
                                    <Calendar
                                        name={field.id}
                                        label={field.title + (field.required ? ' *' : '')}
                                        onChange={(e: any) => controller.changeField(field.id, e)}
                                        value={controller.answer?.fields?.filter((x: any) => x.id == field.id)[0]?.value}
                                        calendarType='gregory'
                                        minDate={field.min_date ?? new Date('0000-01-01')}
                                        disabledDays={controller.general.days.filter(x => !field.allowed_days.includes(x.label)).map(x => x.label) ?? []}
                                    />
                                )}
                            </S.formField>
                        ))}
                    </S.formFields>
                    <S.formSubmit>
                        <S.formSubmitButton onClick={() => controller.saveForm()}>
                            Enviar
                        </S.formSubmitButton>
                    </S.formSubmit>
                        </>
                    )}
                </S.formWrapper>
            ) : (
                <S.formNotFound>
                    <div ref={glitch.ref}>
                        <S.formNotFoundIcon icon={controller.general.icons.faFileCircleXmark} />
                    </div>
                    <S.formNotFoundTitle ref={glitch.ref}>
                        Formulário não encontrado
                    </S.formNotFoundTitle>
                </S.formNotFound>
            )}
        </S.main>
    )
}

export default PublicForm;