import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [collaborators, setCollaborators] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [collaboratorPermissionsModal, setCollaboratorPermissionsModal] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [collaboratorPermissions, setCollaboratorPermissions] = useState<any>([
        {
            key: 'general',
            name: 'configurações gerais',
            permissions: [
                { name: 'Acessar sistema', key: 'access_system' },
                { name: 'Administrador (Acesso completo)', key: 'system_admin' },
                { name: 'Editar configs da empresa', key: 'edit_company' }
            ]
        },
        {
            key: 'category',
            name: 'categoria',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'product',
            name: 'produto',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'service',
            name: 'serviço',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'sale',
            name: 'venda',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'collaborator',
            name: 'colaborador',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'client',
            name: 'cliente',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'supplier',
            name: 'fornecedor',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'schedule',
            name: 'tarefa/agenda',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'form',
            name: 'formulário',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'store',
            name: 'loja',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'automation',
            name: 'automação',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
        {
            key: 'integration',
            name: 'integração',
            permissions: [
                { name: 'Tudo em', key: 'all' },
                { name: 'Visualizar', key: 'view' },
                { name: 'Editar', key: 'edit' },
                { name: 'Excluir', key: 'delete' },
                { name: 'Criar', key: 'create' }
            ]
        },
    ]);

    const personCreateTemplate = {
        title: 'Colaborador',
        subtitle: 'Criar um novo colaborador',
        infos: [
            {
                key: 'name',
                type: "text",
                name: 'Nome',
                value: '',
                disabled: true,
                required: true
            },
            {
                key: 'email',
                type: "text",
                name: 'E-mail',
                value: '',
                disabled: true,
                required: true
            },
            {
                key: 'job_title',
                type: "text",
                name: 'Cargo',
                value: '',
                disabled: true
            },
            {
                key: 'phone',
                type: "phone",
                name: 'Telefone',
                value: '',
                disabled: true
            }
        ]
    }

    const getCollaborators = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('collaborator/list', {
                page: page
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setCollaborators(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar colaboradores', 'error');
            setLoading(false);
        }
    }

    const updateCollaborator = async () => {
        try {
            setLoading(true);
            const response = await general.api.post(`collaborator/update/${currentItem?._id}`, {
                ...currentItem
            });
            if (response.status === 200) {
                general.notify('Colaborador atualizado com sucesso', 'success');
                setEditModalActive(false);
                setCollaboratorPermissionsModal(false);
                getCollaborators();
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao atualizar colaborador', 'error');
            setEditModalActive(false);
            setCollaboratorPermissionsModal(false);
            setLoading(false);
        }
    }

    const handlePermissionChange = (key: string) => {
        let action = key.split('_')[key.split('_').length - 1];
        let category = key.split('_')[0];
        let permission = collaboratorPermissions.find((p: any) => p.key == category);

        if (!currentItem.permissions)
            currentItem.permissions = [];

        let newPermissions = [...currentItem.permissions];

        if (key == 'general_system_admin') {
            if (newPermissions.includes('general_system_admin')) {
                newPermissions = []
            }
            else {
                newPermissions = []
                collaboratorPermissions.forEach((p: any) => {
                    p.permissions.forEach((sp: any) => {
                        newPermissions.push(`${p.key}_${sp.key}`);
                    })
                })
            }
        }
        else {
            if (newPermissions.includes(key)) {
                if (action == 'all' && newPermissions.includes(key)) {
                    newPermissions = newPermissions.filter((p: any) => !p.includes(category));
                }

                newPermissions = newPermissions.filter((p: any) => p != key);
            }
            else {
                if (action == 'all' && !newPermissions.includes(key)) {
                    permission.permissions.forEach((p: any) => {
                        if (!newPermissions.includes(`${category}_${p.key}`)) {
                            newPermissions.push(`${category}_${p.key}`);
                        }
                    });
                }
                else {
                    newPermissions.push(key);
                }

            }
        }

        setCurrentItem((prev: any) => ({
            ...prev, permissions: newPermissions
        }))
    }


    useEffect(() => {
        getCollaborators(true);
    }, [page]);

    useEffect(() => {
        getCollaborators(true);
    }, []);

    useEffect(() => {
        getCollaborators(false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                setEditModalActive(false);
                setCreateModalActive(false);
                setCollaboratorPermissionsModal(false);
            }
        });
    }, []);

    return {
        general,
        personCreateTemplate,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        currentItem,
        setCurrentItem,
        collaborators,
        page,
        setPage,
        totalPages,
        loading,
        collaboratorPermissionsModal,
        setCollaboratorPermissionsModal,
        collaboratorPermissions,
        setCollaboratorPermissions,
        handlePermissionChange,
        updateCollaborator,
        permissionBlock
    }
}

export default useController;