import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemComponent from '../../../components/app/NewItemComponent';
import CardComponent1 from '../../../components/app/CardComponent1';
import AddCompanyModal from '../../../components/app/AddCompanyModal';
import Pagination from '../../../components/general/Pagination';
import moment from 'moment';

const ChooseCompany = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            <AddCompanyModal getCompanies={controller.getCompanies} modalActive={controller.modalActive} setModalActive={controller.setModalActive} />
            <MenuComponent menuPreset={0} />
            <S.generalArea>
                <HeaderBarComponent pageName='Minhas Empresas' />
                <S.operationsArea>
                    <span onClick={() => controller.setModalActive(true)}>
                        <NewItemComponent mainIcon='faBuildingUser' pageItem='Empresa' />
                    </span>
                    {(controller.companies ?? []).map((item: any, index) => {
                            return (
                                <div key={index} onClick={() => controller.chooseCompany(item.company)}>
                                    <CardComponent1 id={item.company._id} key={index} title={item.company.name} subtitle={(moment(item.company.plan_expire_at) <= moment() ? ' (Pendências financeiras)' : null) ?? item.job_title ?? 'Não informado'} image={item.company?.logo ? item.company.logo[0]?.pathCdn : null} imageObj={item.company?.logo ? item.company?.logo[0] : null} info={item.company.info ?? [{icon: 'faUser', text: '0'},{icon: 'faBell', text: '0'},{icon: 'faDollar', text: '0'}]} />
                                </div>
                            );
                        })
                    }
                </S.operationsArea>
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>

    );
}

export default ChooseCompany;