import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import SelectWithSearch from '../Inputs/SelectWithSearch';
/* @ts-ignore */
import BarcodeReader from 'react-barcode-reader';

const CreateFinancialMovementModal = (props: any) => {
    const controller = useController(props);

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onClick={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>Criar Movimentação financeira - {controller.stepName[controller.step].title}</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer step={controller.step}>
                        <BarcodeReader onScan={controller.readBarcode}/>
                        {controller.step == 0 ?
                            <S.step step={controller.step} active={controller.step == 0} mobileMod={true}>
                                <S.movementTypeContainer>
                                    <S.movementType selected={controller.movementType == 1} onClick={() => {
                                        if (controller.movementType == 1){
                                            controller.changeStep('next');
                                        }
                                        controller.setMovementType(1);
                                    }}>
                                        <S.movementTypeIcon icon={controller.general.icons.faArrowUp} />
                                        <S.movementTypeTitle>Entrada</S.movementTypeTitle>
                                        <S.movementTypeDescription>Selecione essa opção para registrar uma entrada de dinheiro</S.movementTypeDescription>
                                    </S.movementType>
                                    <S.movementType selected={controller.movementType == 0} onClick={() => {
                                        if (controller.movementType == 0){
                                            controller.changeStep('next');
                                        }
                                        controller.setMovementType(0);
                                    }}>
                                        <S.movementTypeIcon icon={controller.general.icons.faArrowDown} />
                                        <S.movementTypeTitle>Saída</S.movementTypeTitle>
                                        <S.movementTypeDescription>Selecione essa opção para registrar uma saída de dinheiro</S.movementTypeDescription>
                                    </S.movementType>
                                </S.movementTypeContainer>
                            </S.step>
                            : null}
                        {controller.step == 1 ?
                            <S.step step={controller.step} active={controller.step == 1} mobileMod={true}>
                                <div className='selectWithSearchFinancesModal'>
                                    <SelectWithSearch placeholder='Pesquise ou escaneie um código de barras...' loading={controller.loadingItems} type="withDescription" clearOnClick executeOnSelect={controller.executeOnItemSelect} options={controller.productsAndServices} />
                                </div>
                                {controller.selectedItems.length > 0 ?
                                    <S.movementItemsContainer>
                                        <S.totalValueContainer>
                                            <S.totalValue>Total - R$ {controller.general.formatMoney(controller.totalValue.toString())}</S.totalValue>
                                        </S.totalValueContainer>
                                        {(controller.selectedItems ?? []).map((item: any) => (
                                            <S.movementItem key={controller.general.randomString(20)}>
                                                <S.itemImage src={item.image_url} />
                                                <S.itemContainer>
                                                    <S.itemInfos>
                                                        <S.itemTitle>{item.name}</S.itemTitle>
                                                        <S.deleteItemIconMobile onMouseDown={() => controller.deleteItem(item)} icon={controller.general.icons.faTrash} />
                                                        <S.itemDescription>{item.description}</S.itemDescription>
                                                    </S.itemInfos>
                                                    <S.itemInputs>
                                                        <S.itemInput>
                                                            <S.itemInputLabel>Quantidade:</S.itemInputLabel>
                                                            <S.itemInputField defaultValue={item.quantity} type='number' onBlur={(e) => controller.changeItemQuantity(parseInt(e.target.value), item)} />
                                                        </S.itemInput>
                                                        <S.itemInput>
                                                            <S.itemInputLabel>Valor unitário:</S.itemInputLabel>
                                                            <S.itemInputField defaultValue={controller.general.formatMoney(item.price.toString())} onBlur={(e) => controller.changeItemPrice(e.target.value, item)} onChange={(e) => e.target.value = controller.general.formatMoney(e.target.value)} />
                                                        </S.itemInput>
                                                    </S.itemInputs>
                                                </S.itemContainer>
                                                <S.deleteItemIcon onMouseDown={() => controller.deleteItem(item)} icon={controller.general.icons.faTrash} />
                                            </S.movementItem>
                                        ))}
                                    </S.movementItemsContainer>
                                    :
                                    <S.noItemsContainer>
                                        <S.noItemsIcon icon={controller.general.icons.faExclamationTriangle} />
                                        <S.noItemsTitle>Nenhum item adicionado</S.noItemsTitle>
                                        <S.noItemsDescription>Selecione um item ou utilize um leitor de código de barras para adicioná-lo movimentação financeira</S.noItemsDescription>
                                    </S.noItemsContainer>
                                }
                            </S.step>
                            : null}

                        {controller.step == 2 ?
                            <S.step step={controller.step} active={controller.step == 2} mobileMod={true}>
                                <S.inputsContainer>
                                    <S.itemInput>
                                        <S.itemInputLabel>Título*:</S.itemInputLabel>
                                        <S.itemInputField value={controller.title} onChange={(e) => controller.setTitle(e.target.value)} />
                                    </S.itemInput>
                                    <S.itemInput>
                                        <S.itemInputLabel>Moeda*:</S.itemInputLabel>
                                        <div className='selectWithSearchCurrency'>
                                        <SelectWithSearch defaultValue='BRL (R$)' executeOnSelect={controller.executeOnCurrencySelect} options={
                                            controller.general.getCountries().reduce((countries: any, item: any) => {
                                                if (item.currency != null && item.currency != undefined && item.currency != '') {
                                                    if (!countries.find((country: any) => country.value == item.currency)) {
                                                        countries.push({
                                                            name: `${item.currency} (${item.currencySymbol})`,
                                                            value: item.currency
                                                        });
                                                    }
                                                }
                                                return countries;
                                            }, []).sort((a: any, b: any) => a.name.localeCompare(b.name))} />
                                        </div>
                                    </S.itemInput>
                                    <S.itemInput>
                                        <S.itemInputLabel>Valor total*:</S.itemInputLabel>
                                        <S.itemInputField value={controller.general.formatMoney(controller.totalValue)} onChange={(e) => controller.setTotalValue(e.target.value)} />
                                    </S.itemInput>
                                    <S.itemInput>
                                        <S.itemInputLabel>Data prevista (opcional):</S.itemInputLabel>
                                        <S.itemInputField value={controller.general.customMask(controller.dueAt, "##/##/####", '#', true)} onChange={(e) => controller.setDueAt(e.target.value)} />
                                    </S.itemInput>
                                    <S.itemInput>
                                        <S.itemInputLabel>Data de pagamento (opcional):</S.itemInputLabel>
                                        <S.itemInputField value={controller.general.customMask(controller.paidAt, "##/##/####", '#', true)} onChange={(e) => controller.setPaidAt(e.target.value)} />
                                    </S.itemInput>
                                </S.inputsContainer>
                            </S.step>
                            : null}
                    </S.stepContainer>
                    <S.progressBar>
                        <S.progress percentage={controller.calculateStepPercentage()}></S.progress>
                        <S.progressPercentage>
                            {controller.calculateStepPercentage() + '%'}
                        </S.progressPercentage>
                    </S.progressBar>
                    <S.nextBackBtns>
                        <S.nextBackBtn onClick={() => controller.changeStep('next')}>
                            <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={20}/>
                            {controller.stepName[controller.step].nextBtn ? controller.stepName[controller.step].nextBtn : ''}
                        </S.nextBackBtn>
                        {(controller.step == 0) ? null : <S.nextBackBtn onClick={() => controller.changeStep('back')}>{controller.stepName[controller.step].backBtn ? controller.stepName[controller.step].backBtn : ''}</S.nextBackBtn>}
                    </S.nextBackBtns>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default CreateFinancialMovementModal;