import React from "react";
import { Routes, Route } from "react-router-dom";
import general from "./utils/general";

//app routes
import AccountEdit from "./pages/app/AccountEdit";
import Bills from "./pages/app/Bills";
import ChooseCompany from "./pages/app/ChooseCompany";
import CompanyConfig from "./pages/app/CompanyConfig";
import Dashboard from "./pages/app/Dashboard";
import Home from "./pages/app/Home";
import Login from "./pages/app/Login";
import Page404 from "./pages/app/Page404";
import ProdServiceCategories from "./pages/app/ProdServiceCategories";
import Products from "./pages/app/Products";
import Services from "./pages/app/Services";
import SignUp from "./pages/app/SignUp";
import Stakeholders from "./pages/app/Stakeholders";
import StoreEdit from "./pages/app/StoreEdit";
import Finances from "./pages/app/Finances";
import HumanResources from "./pages/app/HumanResources";
import Suppliers from "./pages/app/Suppliers";
import Agenda from "./pages/app/Agenda";
import Forms from "./pages/app/Forms";
import EditForm from "./pages/app/Forms/EditForm";
import PublicForm from "./pages/app/Forms/PublicForm";
import Stores from "./pages/app/Stores";

//backoffice routes
import BackofficeAccounts from "./pages/backoffice/Accounts";
import BackofficeDashboard from "./pages/backoffice/Dashboard";
import BackofficeLogin from "./pages/backoffice/Login";
import Plans from "./pages/backoffice/Plans";
import PromoCode from "./pages/backoffice/PromoCodes";
import BackofficeLogs from "./pages/backoffice/Logs";
import BackofficeSystemConfigs from "./pages/backoffice/SystemConfigs";

//store routes
import StoreAccount from "./pages/store/Account";
import StoreOrderFinished from "./pages/store/OrderFinished";
import StoreOrders from "./pages/store/Orders";
import StorePage404 from "./pages/store/Page404";
import StoreShoppingCart from "./pages/store/ShoppingCart";
import StoreStore from "./pages/store/Store";

import useStore from "./utils/zustand";

const checkAuth = () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      return general.api.post('/account/authcheck', { token }).then((response: any) => {
        if (response.data.status === 200) {
          if (localStorage.getItem("company") !== null) {
            let currentCompany = localStorage.getItem("company")
            general.api.post('/account-company/list', {
              page: 0,
              limit: 9999999999
            }).then((response) => {
              if (response.status === 200) {
                let companies = response.data.data.data;
                let company = companies.find((c: any) => c.company._id === currentCompany).company;
                localStorage.setItem('company', company._id);
                localStorage.setItem('companyObject', JSON.stringify(company));
              }
            }).catch((err) => {
              console.log(err);
              general.notify('Erro ao buscar empresa', 'error');
            });
          }
          localStorage.setItem("account", JSON.stringify(response.data.data));
          return true;
        }
        else if (response.data.status === 500) {
          localStorage.removeItem("token");
          localStorage.removeItem("company");
          return false;
        }
        else {
          localStorage.removeItem("token");
          localStorage.removeItem("company");
          return false;
        }
      })
    }
    catch (err) {
      return false
    }
  }
  else {
    return false;
  }
}

function App() {
  return (
    <Routes>
      {/* app */}
      {/* <Route path="/" element={<Home />} /> */}
      <Route path="/" element={checkAuth() ? <ChooseCompany /> : <Login />} />
      <Route path="/*" element={<Page404 />} />
      <Route path="/login" element={checkAuth() ? <ChooseCompany /> : <Login />} />
      <Route path="/signup" element={checkAuth() ? <ChooseCompany /> : <SignUp />} />
      <Route path="/app/account/edit" element={<AccountEdit />} />
      <Route path="/app/bills" element={<Bills />} />
      <Route path="/app/company/choose" element={checkAuth() ? <ChooseCompany /> : <Login />} />
      <Route path="/app/company/config" element={checkAuth() ? <CompanyConfig /> : <Login />} />
      <Route path="/app/dashboard" element={checkAuth() ? <Dashboard /> : <Login />} />
      <Route path="/app/prodserv/categories" element={checkAuth() ? <ProdServiceCategories /> : <Login />} />
      <Route path="/app/products" element={checkAuth() ? <Products /> : <Login />} />
      <Route path="/app/services" element={checkAuth() ? <Services /> : <Login />} />
      <Route path="/app/stakeholders" element={checkAuth() ? <Stakeholders /> : <Login />} />
      <Route path="/app/store/:store_id" element={checkAuth() ? <StoreEdit /> : <Login />} />
      <Route path="/app/finances" element={checkAuth() ? <Finances /> : <Login />} />
      <Route path="/app/humanresources" element={checkAuth() ? <HumanResources /> : <Login />} />
      <Route path="/app/suppliers" element={checkAuth() ? <Suppliers /> : <Login />} />
      <Route path="/app/agenda" element={checkAuth() ? <Agenda /> : <Login />} />
      <Route path="/app/forms" element={checkAuth() ? <Forms /> : <Login />} />
      <Route path="/app/form/options/:slug" element={checkAuth() ? <EditForm /> : <Login />} />
      <Route path="/app/stores" element={checkAuth() ? <Stores /> : <Login />} />

      {/* Public routes (no login needed) */}
      <Route path="/io/form/:slug" element={<PublicForm />} />

      {/* backoffice */}
      <Route path="/backoffice" element={checkAuth() ? <BackofficeAccounts /> : <BackofficeLogin />} />
      <Route path="/backoffice/*" element={<Page404 />} />
      <Route path="/backoffice/dashboard" element={checkAuth() ? <BackofficeDashboard /> : <BackofficeLogin />} />
      <Route path="/backoffice/accounts" element={checkAuth() ? <BackofficeAccounts /> : <BackofficeLogin />} />
      <Route path="/backoffice/plans" element={checkAuth() ? <Plans /> : <BackofficeLogin />} />
      <Route path="/backoffice/promo_codes" element={checkAuth() ? <PromoCode /> : <BackofficeLogin />} />
      <Route path="/backoffice/companies" element={checkAuth() ? <PromoCode /> : <BackofficeLogin />} />
      <Route path="/backoffice/system/logs" element={checkAuth() ? <BackofficeLogs /> : <BackofficeLogin />} />
      <Route path="/backoffice/system/configs" element={checkAuth() ? <BackofficeSystemConfigs /> : <BackofficeLogin />} />

      {/* store */}
      <Route path="/store/client/account/:stakeholder_id" element={<StoreAccount />} />
      <Route path="/store/*" element={<StorePage404 />} />
      <Route path="/store/:store_slug" element={<StoreStore />} />
      <Route path="/store/:store_slug/orders" element={<StoreOrders />} />
      <Route path="/store/:store_slug/order/finished" element={<StoreOrderFinished />} />
      <Route path="/store/:store_slug/shoppingcart" element={<StoreShoppingCart />} />
    </Routes>
  );
}

export default App;
