import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultItemProps } from '../../../utils/types';

const ActionCard = (props: any) => {
    const controller = useController();

    return (
        <S.main>
            <S.card>
                {/*  @ts-expect-error */}
                <S.icon icon={controller.general.icons[props.mainIcon ?? 'faAdd']} />
                <S.cardData>
                    <S.title>{props.children}</S.title>
                </S.cardData>
            </S.card>
        </S.main>
    );
}

export default ActionCard;