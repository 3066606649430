import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import ActionCard from '../../../components/app/ActionCard';
import EditCompanyModal from '../../../components/app/EditCompanyModal';
import EditCompanyAddress from '../../../components/app/EditCompanyAddress';
import EditCompanyFiscalData from '../../../components/app/EditCompanyFiscalData';
import EditCompanyPayment from '../../../components/app/EditCompanyPayment';
import EditCompanyEmailIntegration from '../../../components/app/EditCompanyEmailIntegration';

const CompanyConfig = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <EditCompanyModal modalActive={controller.basicDataModal} setModalActive={controller.setBasicDataModal} />
            <EditCompanyAddress modalActive={controller.addressModal} setModalActive={controller.setAddressModal} />
            <EditCompanyFiscalData modalActive={controller.fiscalDataModal} setModalActive={controller.setFiscalDataModal} />
            <EditCompanyPayment modalActive={controller.paymentModal} setModalActive={controller.setPaymentModal} />
            <EditCompanyEmailIntegration modalActive={controller.emailIntegrationConfigModal} setModalActive={controller.setEmailIntegrationConfigModal} />
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Configurar empresa' />
                <S.operationsArea>
                    <div className='content-cards-wrapper' onClick={() => controller.setBasicDataModal(true)}>
                        <ActionCard mainIcon='faClipboardCheck'>
                            Configurar < br /> Dados básicos
                        </ActionCard>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setAddressModal(true)}>
                        <ActionCard mainIcon='faMapLocationDot'>
                            Configurar < br /> Endereço
                        </ActionCard>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setFiscalDataModal(true)}>
                        <ActionCard mainIcon='faMoneyCheckDollar'>
                            Configurar < br /> Dados fiscais
                        </ActionCard>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setPaymentModal(true)}>
                        <ActionCard mainIcon='faFileInvoiceDollar'>
                            Configurar < br /> Pagamento
                        </ActionCard>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setEmailIntegrationConfigModal(true)}>
                        <ActionCard mainIcon='faEnvelopeCircleCheck'>
                            Configurar < br /> integração de email
                        </ActionCard>
                    </div>
                </S.operationsArea>
            </S.generalArea>
        </S.main>
    );
}

export default CompanyConfig;