import { useState, useEffect } from 'react';
import general from '../../../utils/general';

export const useController = () => {
    const [events, setEvents] = useState([]);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [currentMonth, setCurrentMonth] = useState<any>(new Date().getMonth() + 1);
    const [eventsToShowMore, setEventsToShowMore] = useState<any>([]);
    const [showMoreEventsModal, setShowMoreEventsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const eventCreateTemplate = {
        title: 'Novo evento',
        subtitle: 'Crie um novo evento',
        infos: [
            {
                key: 'title',
                name: 'Nome',
                type: "text",
                required: true,
                value: '',
                disabled: true
            },
            {
                key: 'description',
                name: 'Descrição',
                type: "text",
                required: false,
                value: '',
                disabled: true
            },
            {
                key: 'start',
                name: 'Início',
                type: "datetime",
                required: true,
                value: '',
                disabled: true
            },
            {
                key: 'end',
                name: 'Fim',
                type: "datetime",
                required: true,
                value: '',
                disabled: true
            },
            {
                key: 'company',
                name: 'Evento para toda empresa',
                type: "checkbox",
                value: '',
                disabled: true
            },
            {
                key: 'status',
                name: 'Status do evento',
                type: "select",
                required: true,
                value: '',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Aceito',
                        value: '1'
                    },
                    {
                        name: 'Recusado',
                        value: '2'
                    },
                    {
                        name: 'Pendente',
                        value: '3'
                    },
                    {
                        name: 'Cancelado',
                        value: '4'
                    },
                    {
                        name: 'Concluído',
                        value: '5'
                    }
                ]
            },
        ]
    }

    const getEvents = async (searchMonth: any, showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('event/list', {
                searchMonth
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setEvents(response.data.data.map((event: any) => {
                    return {
                        ...event,
                        start: new Date(new Date(event.start).getTime() + 60 * 60 * 3 * 1000),
                        end: new Date(new Date(event.end).getTime() + 60 * 60 * 3 * 1000)
                    }
                }));
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar eventos', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        getEvents(currentMonth, true);
    }, []);

    useEffect(() => {
        getEvents(currentMonth, false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getEvents(currentMonth, true);
    }, [currentMonth]);

    return {
        general,
        events,
        setEvents,
        createModalActive,
        setCreateModalActive,
        editModalActive,
        setEditModalActive,
        currentItem,
        setCurrentItem,
        eventCreateTemplate,
        setCurrentMonth,
        currentMonth,
        eventsToShowMore,
        setEventsToShowMore,
        showMoreEventsModal,
        setShowMoreEventsModal,
        loading,
        permissionBlock
    }
}

export default useController;